import { isArray, isObject, set } from "lodash";
import { z } from "zod";
import { FieldValidator } from "final-form";
import { validatePasswordComplexity } from "modules/auth/utils";
import { messages } from "./messages";
import { AccessType, ScopeDefinition } from "./types";

const isEmpty = (value: any): boolean => !value || (isArray(value) && value.length === 0);

export const notEmptyFieldValidator: FieldValidator<any> = (value, _, meta) =>
  isEmpty(value) ? messages.validation.required(meta?.name || "") : undefined;

export const getNotEmptyValidator =
  (...fields: any[]) =>
  (errors: any, values: any) => {
    return fields.reduce((errors, field) => {
      if (isEmpty(values[field])) {
        errors[field] = messages.validation.required(field);
      }

      return errors;
    }, errors);
  };

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\])|(\[IPv6:(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))\])|([A-Za-z0-9]\.?([A-Za-z0-9-]+\.)*([A-Za-z0-9-])*[A-Za-z0-9]))$/;

export const validateEmail = (email: string) => {
  const parsedEmail = z.string().regex(emailRegex).safeParse(email);
  return parsedEmail.success ? undefined : "Invalid email";
};

export const parseEmail = (email: string) => {
  const emailAllowedCharacters = /[^a-zA-Z0-9_\/–\-@.\+!%&"'*=?~|#$`\(\)\{\}]/g;
  return email?.replace(emailAllowedCharacters, "");
};

export const getNotEmptyValidatorWithLabel =
  (field: any, label = messages.validation.required(field)) =>
  (errors: any, values: any) => {
    if (!values[field] || (isArray(values[field]) && values[field].length === 0)) {
      errors[field] = label;
    }
    return errors;
  };

export const getMaxLengthValidator = (field: string, val: number) => (errors: any, values: any) => {
  if (Boolean(values[field]) && values[field].length > val) {
    errors[field] = messages.validation.maxLength(field);
  }
  return errors;
};

export const getMinLengthValidator = (field: string, val: number) => (errors: any, values: any) => {
  if (!values[field] || values[field].length < val) {
    errors[field] = messages.validation.minLength(field, val);
  }
  return errors;
};

export const passwordValidator = (errors: any, values: any) => {
  const complexityValidation = validatePasswordComplexity(values.password);

  if (complexityValidation) {
    errors.password = complexityValidation;
    return errors;
  }

  if (values.password !== values.password1) {
    errors.password1 = messages.validation.passwordNotIdentical;
  }

  return errors;
};

export const getValidator =
  (...validators: any[]) =>
  (values: any) => {
    return validators.reduce((errors, validator) => validator(errors, values), {});
  };

export const transformRoles = (values: { roles: Array<{ value: string } | string> }) => {
  const roles = (values.roles || []).map((item) => (isObject(item) ? item["value"] : item));

  return Object.assign({}, values, { roles });
};

export const getRolesString = (roles: string[] | null = []) => (roles || []).join(", ");

export const scopeDefinitionValidator = (errors: any, values: any) => {
  const definition = (values.definition || {}) as ScopeDefinition;

  Object.entries(definition).forEach(([entity, rule]) => {
    if (rule.access_type !== AccessType.FullAccess && !rule.ids?.length) {
      set(errors, `definition.${entity}.ids`, `Select value`);
    }
  });

  const allFullAccess = Object.values(definition).every(
    (rule) => rule.access_type === AccessType.FullAccess
  );
  if (allFullAccess) {
    errors.definition = "Scopes require at least one field not be set to Full access";
  }

  return errors;
};
