import React from "react";
import {
  IncidentAssignIcon,
  IncidentCommentDeletedIcon,
  IncidentEditIcon,
  IncidentIgnoredIcon,
  IncidentMessageIcon,
  IncidentNewIcon,
  IncidentResolvedIcon,
  IncidentUserIcon,
} from "assets/icons";
import { AutoAwesome } from "@mui/icons-material";
import { types_CategoryStatusEnum, types_ResolutionStatusEnum } from "api/gen";

export const IncidentStatusText = {
  [types_ResolutionStatusEnum.Ignored]: "Ignored",
  [types_ResolutionStatusEnum.InProgress]: "Assigned",
  [types_ResolutionStatusEnum.Resolved]: "Resolved",
  [types_ResolutionStatusEnum.Unresolved]: "Unresolved",
};

export const ActionType = {
  NEW_INCIDENT: "NEW_INCIDENT",
  AI_EXPLANATION: "AI_EXPLANATION",
  ASSIGNED: "ASSIGNED",
  RE_ASSIGNED: "RE_ASSIGNED",
  RESOLVED: "RESOLVED",
  IGNORED: "IGNORED",
  RE_OPENED: "RE_OPENED",
  EXPLANATION: "EXPLANATION",
  COMMENT: "COMMENT",
  COMMENT_DELETED: "COMMENT_DELETED",
  UNBLOCKED: "UNBLOCKED",
  POLICY_EDIT: "POLICY_EDIT",
  SOURCE_EDIT: "SOURCE_EDIT",
} as const;

export type IncidentActionType = (typeof ActionType)[keyof typeof ActionType];

export const ActionIcon = {
  [ActionType.NEW_INCIDENT]: <IncidentNewIcon />,
  [ActionType.AI_EXPLANATION]: <AutoAwesome />,
  [ActionType.UNBLOCKED]: <IncidentUserIcon />,
  [ActionType.ASSIGNED]: <IncidentAssignIcon />,
  [ActionType.RE_ASSIGNED]: <IncidentAssignIcon />,
  [ActionType.RESOLVED]: <IncidentResolvedIcon />,
  [ActionType.IGNORED]: <IncidentIgnoredIcon />,
  [ActionType.RE_OPENED]: <IncidentMessageIcon />,
  [ActionType.EXPLANATION]: <IncidentMessageIcon />,
  [ActionType.COMMENT]: <IncidentMessageIcon />,
  [ActionType.COMMENT_DELETED]: <IncidentCommentDeletedIcon />,
  [ActionType.POLICY_EDIT]: <IncidentEditIcon />,
  [ActionType.SOURCE_EDIT]: <IncidentEditIcon />,
};

export const ResolutionStatusActionType = {
  [types_ResolutionStatusEnum.Ignored]: ActionType.IGNORED,
  [types_ResolutionStatusEnum.InProgress]: ActionType.ASSIGNED,
  [types_ResolutionStatusEnum.Resolved]: ActionType.RESOLVED,
  [types_ResolutionStatusEnum.Unresolved]: ActionType.RE_OPENED,
};

export const CategoryStatusText = {
  [types_CategoryStatusEnum.CategoryStatusRisky]: "High",
  [types_CategoryStatusEnum.CategoryStatusLowRisky]: "Low",
  [types_CategoryStatusEnum.CategoryStatusApproved]: "Approved",
  [types_CategoryStatusEnum.CategoryStatusNeedsReview]: "Unmatched",
};

export enum SensorType {
  Endpoint = "Endpoint",
  Cloud = "Cloud",
  BrowserExtension = "BrowserExtension",
}

export const sensorTypeToLabel: Record<SensorType, string> = {
  [SensorType.Endpoint]: "Endpoint Sensor",
  [SensorType.Cloud]: "Cloud Sensor",
  [SensorType.BrowserExtension]: "Browser Extension",
};

export const SensorTypesList = Object.values(SensorType);

export enum SensorName {
  SensorNameEndpointApplication = "EndpointSensorApplication",
  SensorNameEndpointFinder = "EndpointSensorFinder",
  SensorNameEndpointExplorer = "EndpointSensorExplorer",
  SensorNameEndpointOneDrive = "EndpointSensorOneDrive",
  SensorNameEndpointOutlook = "EndpointSensorOutlook",
  SensorNameEndpointBrowser = "EndpointSensorBrowser",
  SensorNameEndpointOfficeDocTracker = "EndpointSensorOfficeDocTracker",
  SensorNameEndpointClipboard = "EndpointSensorClipboard",
  SensorNameEndpointMicrosoftWord = "EndpointSensorMicrosoftWord",
  SensorNameEndpointMicrosoftExcel = "EndpointSensorMicrosoftExcel",
  SensorNameEndpointMicrosoftPowerPoint = "EndpointSensorMicrosoftPowerPoint",

  SensorNameCloudOneDrive = "CloudSensorOneDrive",
  SensorNameCloudEmailOffice365 = "CloudSensorEmailOffice365",
  SensorNameCloudEmailGoogle = "CloudSensorEmailGoogle",
  SensorNameCloudSalesforce = "CloudSensorSalesforce",

  SensorNameBrowserExtension = "BrowserExtensionSensor",

  SensorNameSlack = "EndpointSensorSlack",
}
