export enum ScopeEntity {
  Datasets = "datasets",
  UserGroups = "user_groups",
}

export enum AccessType {
  Include = "include",
  Exclude = "exclude",
  FullAccess = "full",
}

export type ScopeDefinition = Record<
  ScopeEntity,
  {
    ids?: string[];
    access_type: AccessType;
  }
>;
