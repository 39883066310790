import React from "react";
import classnames from "classnames";
import MTabs, { TabsProps } from "@mui/material/Tabs";
import MTab, { TabProps } from "@mui/material/Tab";
import { css } from "ui/css";

const styles = {
  root: css({
    "width": "fit-content",
    "background": "#f8f9fb",
    "borderRadius": "3px",
    "--tab-min-height": "30px",
    "minHeight": "var(--tab-min-height)",
    "--tabs-focus-outline-size": "4px",
  }),
  tab: css({
    zIndex: 1,
    minHeight: "var(--tab-min-height)",
    textTransform: "capitalize",
    fontWeight: 400,
  }),
  fittab: css({
    minWidth: "0 !important",
  }),
  flexContainer: css({
    paddingLeft: "var(--tabs-focus-outline-size)",
    paddingRight: "var(--tabs-focus-outline-size)",
  }),
  indicator: css({
    height: "calc(100% - var(--tabs-focus-outline-size) * 2)",
    background: "#dde5eb",
    pointerEvents: "none",
    marginTop: "var(--tabs-focus-outline-size)",
    marginBottom: "var(--tabs-focus-outline-size)",
    borderRadius: "3px",
    minWidth: "20px",
  }),
};

// TODO use proper way of defining how to hide type if less than 1 element is present
export const Tabs = React.forwardRef(function Tabs<T extends React.ElementType = "div">(
  props: TabsProps<T>,
  ref: any
) {
  if (React.Children.count(props.children) === 0) {
    return <div />;
  }
  if (props.hidden && React.Children.count(props.children) <= 1) {
    return <div />;
  }

  return (
    <MTabs
      ref={ref}
      {...props}
      classes={{
        ...props.classes,
        indicator: styles.indicator().className,
        flexContainer: styles.flexContainer().className,
      }}
      className={styles.root({ className: props.className }).className}
    />
  );
} as typeof MTabs) as typeof MTabs;

export const Tab = function Tab(props: TabProps) {
  return <MTab {...props} className={styles.tab({ className: props.className }).className} />;
} as typeof MTab;

export const FitTab = function Tab(props: TabProps) {
  return (
    <MTab
      {...props}
      className={classnames(props.className, styles.tab().className, styles.fittab().className)}
    />
  );
} as typeof MTab;
