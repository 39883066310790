export * from "./locationsConfigurationInfo";
import contentAnalyzerOptions from "assets/content-attributes/PredefinedContentControlLists.json";
import * as IState from "../state/state.h";

const contentOptionsKv: {
  [k: string]: IState.IContent;
} = contentAnalyzerOptions.predefinedContentCondition.reduce<Record<string, any>>((acc, el) => {
  acc[el.name] = { value: el.name, label: el.title + " " + (el.countryTitle || "") };
  return acc;
}, {});

function makeDefault(key: any) {
  return { value: key, label: key ? key.split(":")[0] : "", attributes: [] };
}

// TODO refactor format with 1:1:1 to something more reasonable
export const getContentAttribute = (key: string, values?: any): IState.IContent => {
  const [valueKey, minWeight, minCount] = key.split(":");
  const kvObj = contentOptionsKv[valueKey] || makeDefault(key);
  return { ...kvObj, minWeight: Number(minWeight), minCount: Number(minCount), ...values };
};
