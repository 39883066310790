import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  CircularProgress,
  ButtonProps,
} from "@mui/material";
import { usePopover } from "libs/hooks";
import { Box } from "ui/atoms/Box";
import { styled } from "ui/css";

const layoutMessages = {
  exportAll: "Export all available columns",
};

const ButtonsContainer = styled(ButtonGroup, {
  boxShadow: "none",
});

const Menu = styled(MenuList, {
  padding: "5px 0",
  marginTop: -2,
});

export const ArrowMenuButton = styled(Button, {
  minWidth: "27px !important",
  width: 27,
});

type ExportButtonProps = {
  "children"?: React.ReactNode;
  "isLoading": boolean;
  "data-testid": string;
  "url": string;
  "exportAllUrl"?: string;
};

export function ExportButton({
  children = (<>Export</>) as React.ReactNode,
  isLoading,
  "data-testid": testId,
  url,
  exportAllUrl,
}: ExportButtonProps) {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    actions.onClose();
  };

  const [popover, actions] = usePopover((api) => (
    <Popper
      {...api}
      anchorEl={anchorRef.current}
      role={undefined}
      style={{ zIndex: 3 }}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <Menu id="export-button-menu">
                <a
                  style={{
                    textDecoration: "none",
                    pointerEvents: isLoading ? "none" : undefined,
                  }}
                  href={exportAllUrl}
                >
                  <MenuItem dense onClick={togglePopover}>
                    {layoutMessages.exportAll}
                  </MenuItem>
                </a>
              </Menu>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  ));

  const togglePopover = (ev: React.MouseEvent<HTMLElement>) =>
    actions.open ? actions.onClose() : actions.onOpen(anchorRef.current || ev.target);

  return (
    <>
      <ButtonsContainer
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        color="secondary"
      >
        <Button
          href={url}
          data-testid={testId}
          variant="contained"
          style={{ textDecoration: "none", pointerEvents: isLoading ? "none" : undefined }}
          startIcon={
            isLoading ? (
              <Box as={CircularProgress} sx={{ marginX: "2px" }} size={16} color="inherit" />
            ) : (
              <GetAppIcon fontSize="small" />
            )
          }
        >
          {children}
        </Button>
        {exportAllUrl && (
          <ArrowMenuButtonComponent
            aria-label="export all menu"
            isPopoverOpened={actions.open}
            onClick={togglePopover}
          />
        )}
      </ButtonsContainer>
      {popover}
    </>
  );
}

export type ArrowMenuButtonComponentProps = ButtonProps & {
  isPopoverOpened?: boolean;
};

export const ArrowMenuButtonComponent = ({
  isPopoverOpened,
  ...props
}: ArrowMenuButtonComponentProps) => {
  return (
    <ArrowMenuButton
      size="small"
      aria-controls={isPopoverOpened ? "export-button-menu" : undefined}
      aria-expanded={isPopoverOpened ? "true" : undefined}
      aria-haspopup="menu"
      type="button"
      color="secondary"
      {...props}
    >
      {isPopoverOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
    </ArrowMenuButton>
  );
};
