import {
  createTheme,
  adaptV4Theme,
  DeprecatedThemeOptions,
  ThemeOptions,
} from "@mui/material/styles";

const shadows = [
  "none",
  "0px 1px 2px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px -1px 1px -1px rgb(0 0 0 / 12%)",
  "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
  "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
  "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
  "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
  "0px 5px 6px -3px rgb(0 0 0 / 20%), 0px 9px 12px 1px rgb(0 0 0 / 14%), 0px 3px 16px 2px rgb(0 0 0 / 12%)",
  "0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%)",
  "0px 6px 7px -4px rgb(0 0 0 / 20%), 0px 11px 15px 1px rgb(0 0 0 / 14%), 0px 4px 20px 3px rgb(0 0 0 / 12%)",
  "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 12px 17px 2px rgb(0 0 0 / 14%), 0px 5px 22px 4px rgb(0 0 0 / 12%)",
  "0px 7px 8px -4px rgb(0 0 0 / 20%), 0px 13px 19px 2px rgb(0 0 0 / 14%), 0px 5px 24px 4px rgb(0 0 0 / 12%)",
  "0px 7px 9px -4px rgb(0 0 0 / 20%), 0px 14px 21px 2px rgb(0 0 0 / 14%), 0px 5px 26px 4px rgb(0 0 0 / 12%)",
  "0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%)",
  "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
  "0px 8px 11px -5px rgb(0 0 0 / 20%), 0px 17px 26px 2px rgb(0 0 0 / 14%), 0px 6px 32px 5px rgb(0 0 0 / 12%)",
  "0px 9px 11px -5px rgb(0 0 0 / 20%), 0px 18px 28px 2px rgb(0 0 0 / 14%), 0px 7px 34px 6px rgb(0 0 0 / 12%)",
  "0px 9px 12px -6px rgb(0 0 0 / 20%), 0px 19px 29px 2px rgb(0 0 0 / 14%), 0px 7px 36px 6px rgb(0 0 0 / 12%)",
  "0px 10px 13px -6px rgb(0 0 0 / 20%), 0px 20px 31px 3px rgb(0 0 0 / 14%), 0px 8px 38px 7px rgb(0 0 0 / 12%)",
  "0px 10px 13px -6px rgb(0 0 0 / 20%), 0px 21px 33px 3px rgb(0 0 0 / 14%), 0px 8px 40px 7px rgb(0 0 0 / 12%)",
  "0px 10px 14px -6px rgb(0 0 0 / 20%), 0px 22px 35px 3px rgb(0 0 0 / 14%), 0px 8px 42px 7px rgb(0 0 0 / 12%)",
  "0px 11px 14px -7px rgb(0 0 0 / 20%), 0px 23px 36px 3px rgb(0 0 0 / 14%), 0px 9px 44px 8px rgb(0 0 0 / 12%)",
  "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
];

const primaryMain = "#43A7EA";
const primaryLight = "#7FD8FF";
const primaryDark = "#0078B7";

const textPrimary = "#1F1F1F";
const textSecondary = "#757575";

const dividerColor = "#DFE1E3";
const dividerColorHover = "#F1F3F4";

const defaultBackground = "#fff";
const secondaryBackground = "#F1F3F4";
const grey1 = "#EDEDED";
const grey2 = "#424242";
const actionActiveColor = "#7b7f84";
const infoBackgroundColor = "rgba(67, 167, 234, 0.2)";

const spacing = 5;
const fontSize = 14;
const borderRadius = 2;
const htmlFontSize = 16;
const getSpacing = (k: any) => spacing * k;

const coef = fontSize / 14;
const pxToRem = (size: any) => `${(size / htmlFontSize) * coef}rem`;
const buildVariant = (color = textPrimary, fontWeight: number, size: number, casing = {}) => ({
  color,
  fontWeight,
  fontSize: pxToRem(size),
  ...casing,
});

const caseAllCaps = {
  textTransform: "uppercase",
};

const headlineFontStyle = buildVariant(textPrimary, 500, 20); // Headline
const titleFontStyle = buildVariant(textPrimary, 500, 16, caseAllCaps); // SEARCH,
const subtitle1FontStyle = buildVariant(textPrimary, 400, 16); // Advanced Search,
const subtitle2FontStyle = buildVariant(textPrimary, 500, 14, caseAllCaps); // ENDPOINT GROUPS
const body1FontStyle = buildVariant(textPrimary, 400, 15); // CV sharing
const body2FontStyle = buildVariant(textPrimary, 400, 14); // Users who interacted with this dataflow
const captionFontStyle = buildVariant(textSecondary, 400, 14); // Users who interacted with this dataflow
const tabTableLabelFontStyle = buildVariant(textSecondary, 500, 13, caseAllCaps); // tab label / table

const borders = {
  border1: `1px solid #f1f1f1`,
};

const themeObg: DeprecatedThemeOptions = {
  spacing: getSpacing,
  shape: {
    borderRadius,
  },
  typography: {
    fontSize,
    h1: {},
    h2: {},
    h3: {},
    h4: {},
    h5: headlineFontStyle, // Headline
    h6: titleFontStyle, // SEARCH,
    subtitle1: subtitle1FontStyle, // Advanced Search,
    subtitle2: subtitle2FontStyle, // ENDPOINT GROUPS
    body1: body1FontStyle, // CV sharing
    body2: body2FontStyle, // Users who interacted with this dataflow
    button: {},
    caption: captionFontStyle, // choose template
    overline: {},
  },

  palette: {
    error: {
      main: "#d32f2f",
      light: "#e57373",
    },
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark: primaryDark,
    },
    secondary: {
      main: "#737373",
    },
    divider: dividerColor,
    background: {
      default: defaultBackground,
    },
    text: {
      primary: textPrimary,
      secondary: textSecondary,
    },
    action: {
      // The color of an active action like an icon button.
      active: actionActiveColor,
      // copied this colors from MU v3 to have the correct colors according to our theme

      // The color of an hovered action.
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.08,
      // The color of a selected action.
      selected: "rgba(0, 0, 0, 0.14)",
      selectedOpacity: 0.14,
      // The color of a disabled action.
      disabled: "rgba(0, 0, 0, 0.26)",
      // The background color of a disabled action.
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    } as any,
  },
  shadows: [...shadows] as any,
  overrides: {
    MuiSvgIcon: {
      colorAction: {
        color: "#757575",
      },
      fontSizeSmall: {
        fontSize: 18,
      },
    },
    MuiToolbar: {
      root: {
        background: defaultBackground,
      },
    },

    MuiFormLabel: {
      root: {
        marginBottom: getSpacing(1),
        ...captionFontStyle,
        lineHeight: 1,
      },
    },

    MuiFormHelperText: {
      root: {
        ...captionFontStyle,
        fontSize: 12,
      },
    },

    MuiSelect: {
      select: {
        ...body1FontStyle,
        "height": "1.1875em",
        "minHeight": "1.1875em",
        "lineHeight": "1.1875em",
        "fontSize": 14,
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          left: 0,
          right: 0,
          bottom: 0,
          content: `""`,
          position: "absolute",
          borderBottom: `1px solid ${dividerColor}`,
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: `2px solid ${dividerColorHover}`,
        },
      },
    },
    MuiInputBase: {
      input: {
        borderColor: dividerColor,
        padding: "10px 0px 10px 0px",
        height: "1.1876em",
        ...body1FontStyle,
      },
      inputMultiline: {
        padding: 0,
        paddingLeft: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        ...body1FontStyle,
        "borderRadius": borderRadius,

        "& $notchedOutline": {
          borderColor: dividerColorHover,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: dividerColor,
        },

        "background": secondaryBackground,

        "&$focused": {
          background: defaultBackground,
        },
      },

      inputSizeSmall: {
        paddingTop: 12,
        paddingBottom: 12,
      },
      adornedStart: {
        paddingLeft: `${getSpacing(2.3)}px`,
      },
    },

    MuiInputLabel: {
      formControl: {
        right: 8,
      },
      outlined: {
        "left": 0,
        "right": "auto",
        "transform": "translate(14px, 20px) scale(1)",
        "&$sizeSmall": {
          transform: "translate(14px, 15px) scale(1)",
        },
        "&$shrink": {
          transform: "translate(18px, -6px) scale(0.75)",
        },
      },
      shrink: {
        transform: "translate(0, 1.5px) scale(1)",
      },
    },
    MuiButton: {
      root: {},
      contained: {
        boxShadow: "none",
        padding: `3px ${getSpacing(2)}px`,
      },
      text: {
        padding: `3px ${getSpacing(2)}px`,
      },
      outlined: {
        padding: `2px ${getSpacing(2)}px`,
      },
      sizeSmall: {
        minWidth: 40,
      },
    },
    MuiFab: {
      root: {},
      primary: {
        color: "#fff",
      },
    },
    MuiPaper: {
      root: {
        background: "white",
      },
    },
    MuiAppBar: {},
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "$labelcolor",
        },
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
        textDecoration: "none",
      },
    },
    MuiDialog: {
      paperWidthXs: {
        maxWidth: 430,
      },
    },

    MuiDialogTitle: {
      root: {
        padding: `${getSpacing(4)}px ${getSpacing(5)}px`,
      },
    },
    MuiDialogContent: {
      root: {
        padding: `${getSpacing(0)}px ${getSpacing(5)}px  ${getSpacing(4)}px`,
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${getSpacing(0)}px ${getSpacing(5)}px  ${getSpacing(4)}px `,
        margin: 0,
      },
      // action: {
      //   "&:last-child": {
      //     marginRight: 0
      //   }
      // }
    },
    MuiTabs: {
      indicator: {
        minWidth: 115,
      },
    },
    MuiTab: {
      root: {
        "minWidth": "115px !important",
        "&:not(.Mui-selected)": {
          opacity: 0.7,
        },
      },

      /* Styles applied to the root element if `textColor="primary"`. */
      textColorPrimary: {
        color: textPrimary,
      },
    },
    MuiList: {
      root: {
        "&:focus": {
          outline: "none",
        },
      },
    },
    MuiListItem: {
      root: {
        paddingTop: `${getSpacing(2)}px`,
        paddingBottom: `${getSpacing(2)}px`,
      },
      dense: {
        paddingTop: `${getSpacing(1)}px`,
        paddingBottom: `${getSpacing(1)}px`,
      },
    },
    MuiListSubheader: {
      root: {
        ...tabTableLabelFontStyle,
        lineHeight: "40px",
      },
    },
    MuiListItemIcon: {
      root: {
        marginRight: 5,
        minWidth: 24,
      },
    },

    MuiListItemText: {
      root: {
        "padding": 0,
        "&$dense": {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },

    MuiTableCell: {
      root: {
        ...body1FontStyle,
        borderBottom: `1px solid ${dividerColor}`,
      },
      head: {
        ...tabTableLabelFontStyle,
      },
    },
    MuiMenuItem: {
      root: {
        ...body1FontStyle,
      },
    },
    MuiChip: {
      root: {
        height: 26,
        maxWidth: "100%",
      },
      icon: {
        color: undefined,
        marginLeft: 10,
        fontSize: 20,
      },
      deleteIcon: {
        margin: `0 10px 0 -2px`,
        fontSize: 20,
      },
      label: {
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    } as any,
    MuiAccordionSummary: {
      root: {
        "&$focused": {
          backgroundColor: "none",
        },
      },
    },
    MuiTablePagination: {
      root: {
        color: textSecondary,
        overflow: "none",
      },
      toolbar: {
        minHeight: 56,
      },
      selectIcon: {
        top: "5px",
        right: "-4px",
      },
    },
  },

  props: {
    MuiCheckbox: {
      color: "primary",
    },
    MuiRadio: {
      color: "primary",
    },
    MuiSwitch: {
      color: "primary",
    },
    MuiIconButton: {
      disableRipple: true,
    },
    MuiPaper: {
      elevation: 1,
    },
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
};

const light = (n: number) => `hsl(0, 0%, calc(100% - ${n * 5}%))`;
const customProperties = {
  borders,
  panelBackground: {
    selectedColor: "#E6EAEE",
  },
  light,
  grey: {
    listViewAttributes: "#5E6C77",
    grey1,
    grey2,
  },
  secondaryBackground,
  drawerWidth: 270,
  infoBackgroundColor,
  riskyColors: {
    "100": "#F4C03B",
    "200": "#F48C40",
    "300": "#D95946",
  },
};

export const themeV5Obj: ThemeOptions = {
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "textTransform": "none",
          "&.MuiTab-textColorPrimary": {
            textTransform: "none",
          },
          "&.Mui-selected": {
            textTransform: "none",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            "&[type=button]": {
              color: "white",
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: 3,
          fontSize: "1.125rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textSizeSmall: {
          padding: "4px 5px",
          fontSize: "0.8125rem",
        },
        containedSecondary: {
          backgroundColor: "#e0e0e0",
        },
        textPrimary: {
          "color": primaryMain,
          "&:hover": {
            background: "rgba(31, 31, 31, 0.08)",
          },
        },
        outlined: {
          border: "1px solid rgba(0, 0, 0, 0.23)",
        },
        root: {
          "color": "#1F1F1F",
          "&:hover": {
            backgroundColor: "rgba(31, 31, 31, 0.08)",
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        contained: {
          color: "rgba(0, 0, 0, 0.87)",
        },
        outlinedSizeLarge: {
          padding: "7px 21px",
        },

        sizeLarge: {
          padding: "8px 21px",
        },

        textError: {
          color: "#b71c1c",
        },
        textInfo: {
          color: "rgb(158, 158, 158)",
        },
        containedPrimary: {
          "color": "#fff",
          "&:hover": {
            backgroundColor: "#0078B7",
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          },
        },
        containedError: {
          "color": "#fff",
          "&:hover": {
            backgroundColor: "#b71c1c",
            boxShadow:
              "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorError: {
          backgroundColor: "#f44336",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          "&.Mui-disabled.MuiSwitch-switchBase": {
            "color": "#bdbdbd",
            "&.Mui-checked": {
              "color": "#8CBBE8",
              " + .MuiSwitch-track": {
                backgroundColor: "#C5DDF3",
                opacity: 1,
              },
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          textTransform: "none",
          fontSize: "1.25rem",
          fontWeight: 500,
          lineHeight: 1.334,
          letterSpacing: "0em",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#F1F3F4",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "background": "#F1F3F4",
          "&.Mui-focused": {
            background: "#fff",
          },

          "&:hover:not(.Mui-focused)  .MuiOutlinedInput-notchedOutline": {
            borderColor: "#DFE1E3",
          },
        },
        input: {
          "height": "1.1876em",
          "padding": "18.5px 14px",
          "fontSize": "0.9375rem",
          "fontWeight": 400,

          "&.MuiInputBase-inputAdornedEnd": {
            paddingRight: 0,
          },

          "&.MuiInputBase-inputAdornedStart": {
            paddingLeft: 0,
          },
        },
        notchedOutline: {
          "borderColor": "transparent",
          "&:before": {
            left: 0,
            right: 0,
            bottom: 0,
            content: `""`,
            position: "absolute",
            borderBottom: `1px solid ${dividerColor}`,
          },
        },
      },
    },
  },
};

export const theme = { ...customProperties, ...createTheme(adaptV4Theme(themeObg), themeV5Obj) };
export const colors = theme.palette;
function asCssVars(vars: Record<string, string>) {
  return (
    Object.keys(vars).reduce((acc, el) => {
      acc += `--${el}: ${vars[el]};\n`;
      return acc;
    }, ":root{\n") + "}"
  );
}

export function setupCssVars() {
  const style = document.createElement("style");
  style.innerHTML = asCssVars({
    space: spacing + "px",
    disabledColor: theme.palette.grey["400"],
  });
  document.head.appendChild(style);
}
