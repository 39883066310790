export const messages = {
  notSupportedTitle: "Your screen size is not supported",
  notSupportedSubtitle: "Please open the Cyberhaven dashboard from a device with a larger display",
  pages: {
    dataFlows: "Data Flows",
    incidents: "Incidents",
    listsMatching: "Lists",
    analyticsDashboard: "Visual Analytics",
    cloudSensors: "Cloud Sensors",
    endpointSensors: "Endpoint Sensors",
    preferences: "Preferences",
    accounts: "Accounts",
    reports: "Reports",
  },
  betaLabel: "beta",
};
