import axios, { AxiosInstance, AxiosRequestConfig, AxiosStatic } from "axios";
import { logout } from "modules/auth/effects";
import { notification } from "modules/notification";
import { getCurrentSessionID } from "./get-current-session-id";
import { getToken } from "../storage";

export function logoutUser(error = {} as any) {
  let message = "Your session has expired. Please log in again.";
  message = error?.response?.data?.error || message;
  notification.error({ message });
  logout();
  if (
    !["/login", "/register", "/signup", "/cyberhaven-login"].find((el) =>
      window.location.pathname.includes(el)
    )
  ) {
    window.history.pushState(
      { from: window.location.pathname + window.location.search },
      "",
      "/login"
    );
  } else {
    console.error("unauhenticated call on login page");
  }

  return Promise.reject(error);
}

const publicEndpoints = [
  "/v2/user-management/enabled-auth-providers",
  "/v2/user-management/saml/list-ids",
];
// just get the token but do not logout if it doesn't exists
export async function requestInterceptor(
  config: AxiosRequestConfig,
  getToken: () => Promise<string>
) {
  // No need for token for public endpoints that used on login page
  const isPublic = publicEndpoints.includes(config.url || "");
  if (isPublic) {
    return config;
  }

  let token = "";
  try {
    token = await getToken();
    const sessionId = getCurrentSessionID();
    if (!token) {
      // If we have no token, then we do not need to log out if unauthorized error appears
      (config as any).meta = { ...(config as any).meta, ignoreUnauthorized: true };

      throw new Error("No token set");
    }
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.headers = { Authorization: `Bearer ${token}` };
    }
    config.headers["X-Session-ID"] = sessionId;
  } catch (e) {
    console.error(e);
  }
  return config;
}

export function responseErrorInterceptor(error: { config?: any; response?: any } = {}) {
  const { config, response } = error;

  // (#8064) Fix for auth token gets removed from cookies for the first tab, and thus other tabs are not logged out.
  // We allow 401 log out on the url which will never be a part of the login flow.
  if (config?.url !== "/v1/graphql" && config?.meta?.ignoreUnauthorized) {
    return Promise.reject(error);
  }

  if (response?.status === 401 && error.response?.data?.detail?.Code !== "mfa-required") {
    return logoutUser(error);
  }

  return Promise.reject(error);
}

export const getAuthToken = getToken;

export function install(client: AxiosStatic | AxiosInstance = axios) {
  client.interceptors.request.use((cfg) => requestInterceptor(cfg, getAuthToken));
  if (process.env.NODE_ENV !== "test") {
    client.interceptors.response.use((response) => response, responseErrorInterceptor);
  }
}
