import { messages } from "../messages";

export const zones = {
  internal: {
    label: messages.zones.internal,
    value: "Internal",
    color: "#E0EEFD",
  },
  external: {
    label: messages.zones.external,
    value: "External",
    color: "rgba(232, 99, 102, 0.2)",
  },
  undefined: {
    label: messages.zones.undefined,
    value: "Undefined",
    color: "#B4B4B4",
  },
};

export const mediaCategories = {
  usb: {
    label: messages.mediaCategory.usb,
    value: "usb",
  },
  optical: {
    label: messages.mediaCategory.optical,
    value: "optical",
  },
};

export const locations = {
  all: {
    label: messages.locations.all,
    labelPlural: messages.locations.all,
    value: "*",
    color: "#ccc",
    keywords: [
      {
        label:
          "Email account or Email subject or Hostname or Path or Removable device name or Salesforce account name or Url",
      },
    ],
  },
  mail: {
    label: messages.locations.mail,
    labelPlural: messages.locations.mailPlural,
    value: "mail",
    color: "#4CCBAB",
    clusterColor: "#4CCBAB33",
    keywords: ["email_account", { label: "Email subject" }],
    source: true,
    destination: true,
    fields: {
      domain: {
        name: "mail.domain",
        suggestName: "domain",
        label: messages.locations.mailDomain,
      },
      email_account: {
        suggestName: "email_account",
        name: "mail.email_account",
        label: messages.locations.mailAccount,
      },
    },
  },
  cloud_storage: {
    label: messages.locations.cloudStorage,
    labelPlural: messages.locations.cloudStoragePlural,
    value: "cloud_storage",
    color: "#3E75C8",
    clusterColor: "#3E75C833",
    keywords: ["path"],
    source: true,
    destination: true,
    fields: {
      cloud_provider: {
        name: "cloud_storage.cloud_provider",
        suggestName: "cloud_provider",
        label: messages.locations.cloudProvider,
      },
      path: {
        name: "cloud_storage.path",
        suggestName: "path",
        label: messages.locations.cloudStoragePath,
      },
    },
  },
  cloud_apps: {
    label: messages.locations.cloudApps,
    labelPlural: messages.locations.cloudAppsPlural,
    value: "cloud_apps",
    color: "#3E75C8",
    clusterColor: "#3E75C833",
    keywords: ["salesforce_account_name"],
    source: true,
    destination: true,
    fields: {
      cloud_app: {
        name: "cloud_apps.cloud_app",
        label: messages.locations.cloudApp,
      },
      salesforce_account_name: {
        name: "cloud_apps.salesforce_account_name",
        label: messages.locations.cloudAppsAccountName,
      },
    },
  },
  website: {
    label: messages.locations.website,
    labelPlural: messages.locations.websitePlural,
    value: "website",
    color: "#8B60AD",
    clusterColor: "#8B60AD33",
    keywords: ["url"],
    source: true,
    destination: true,
    fields: {
      domain: {
        name: "website.domain",
        label: messages.locations.websiteDomain,
      },
      url: {
        name: "website.url",
        label: messages.locations.websiteUrl,
      },
      category: {
        name: "website.category",
        label: messages.locations.websiteCategory,
      },
    },
  },
  share: {
    label: messages.locations.share,
    labelPlural: messages.locations.sharePlural,
    value: "share",
    color: "#E56574",
    clusterColor: "#E5657433",
    keywords: ["path", "hostname"],
    source: true,
    destination: true,
    fields: {
      hostname: {
        name: "share.hostname",
        label: messages.locations.shareHostname,
      },
      group_name: {
        name: "share.group_name",
        label: messages.locations.shareGroupName,
      },
      path: {
        name: "share.path",
        label: messages.locations.sharePath,
      },
    },
  },
  endpoint: {
    label: messages.locations.endpoint,
    labelPlural: messages.locations.endpointPlural,
    value: "endpoint",
    color: "#FE9E46",
    clusterColor: "#FE9E4633",
    keywords: ["path", "hostname"],
    source: true,
    destination: true,
    fields: {
      hostname: {
        name: "endpoint.hostname",
        label: messages.locations.endpointHostname,
      },
      group_name: {
        name: "endpoint.group_name",
        label: messages.locations.endpointGroupName,
      },
      local_user_name: {
        name: "endpoint.local_user_name",
        label: messages.locations.endpointLocalUserName,
      },
      path: {
        name: "endpoint.path",
        label: messages.locations.endpointPath,
      },
    },
  },
  endpoint_apps: {
    label: messages.locations.endpointApps,
    labelPlural: messages.locations.endpointAppsPlural,
    value: "endpoint_apps",
    color: "#FE9E46",
    clusterColor: "#FE9E4633",
    keywords: ["path", "hostname"],
    source: true,
    destination: true,
    fields: {
      app_commandline: {
        name: "endpoint_apps.app_commandline",
        label: messages.locations.endpointAppsCommandline,
      },
      group_name: {
        name: "endpoint_apps.group_name",
        label: messages.locations.endpointAppsGroupName,
      },
      app_name: {
        name: "endpoint_apps.app_name",
        label: messages.locations.endpointAppsAppName,
      },
      hostname: {
        name: "endpoint_apps.hostname",
        label: messages.locations.endpointAppsHostname,
      },
      local_user_name: {
        name: "endpoint_apps.local_user_name",
        label: messages.locations.endpointAppsLocalUserName,
      },
      path: {
        name: "endpoint_apps.path",
        label: messages.locations.endpointAppsPath,
      },
    },
  },
  removable_media: {
    label: messages.locations.removableMedia,
    labelPlural: messages.locations.removableMediaPlural,
    value: "removable_media",
    color: "#2ED6D9",
    clusterColor: "#2ED6D933",
    keywords: ["path", "deviceName"],
    source: true,
    destination: true,
    fields: {
      local_user_name: {
        name: "removable_media.local_user_name",
        label: messages.locations.removableMediaLocalUserName,
      },
      deviceName: {
        name: "removable_media.removable_device_name",
        label: messages.locations.removableMediaDeviceName,
      },
      deviceSerial: {
        name: "removable_media.removable_device_id",
        label: messages.locations.removableMediaSerialNumber,
      },
      mediaCategory: {
        name: "removable_media.media_category",
        label: messages.locations.mediaCategory,
      },
      path: {
        name: "removable_media.path",
        label: messages.locations.removableMediaPath,
      },
    },
  },
  printer: {
    label: messages.locations.printer,
    labelPlural: messages.locations.printerPlural,
    value: "printer",
    color: "#ABDA7C",
    clusterColor: "#ABDA7C33",
    keywords: ["printerName"],
    source: false,
    destination: true,
    fields: {
      printerName: {
        name: "printer.printer_name",
        label: messages.locations.printerName,
      },
    },
  },
};

export const getLocationLabel = (type: string) => {
  return locations?.[type as keyof typeof locations]?.label as string;
};

export const getLocationLabelPlural = (type: string) => {
  return locations?.[type as keyof typeof locations]?.labelPlural as string;
};
