import { capitalize } from "lodash";

export const messages = {
  failedToSendAuthLink: "Failed to send auth link.",
  authLinkSent: "Authentication link was sent.",
  setupSmsAuth: "Setup SMS authentication",
  setupSms: `Please enter your mobile phone number to verify your account. You will receive SMS
  with verification code you need to provide on the next step`,
  setupSmsConfirm: (phone: any) => `Verification code has been sent to ${phone}.`,
  setupChangePhoneNumber: `Change phone number.`,
  resendAuthCode: "Send auth link",
  resetAuthMethod: "Password was reset.",
  resetAuthProivider: "Auth Provider was reset.",
  resetAuthProividerInProgress: "Auth Provider reset in progress.",
  resetAuthProividerFailed: "Auth Provider reset failed.",
  authResetMessage: "Reset email link was sent to the user.",
  resetMFAMethod: "MFA was reset.",
  notifications: {
    confirmAuth: "Please reauthenticate to proceed",
    statuses: {
      created: "created",
      updated: "updated",
      removed: "removed",
    },
    getNotificationText: (status: any) => `User has been ${status}`,
    getDirectoryNotificationText: (status: any) => `Directory has been ${status}`,
    passUpdated: "User password has been updated",
    linkCopied: "User sign up link was copied to the clipboard",
  },
  validation: {
    passwordNotIdentical: "Passwords are not identical",
    required: (name: string) => `${capitalize(name)} field is required`,
    maxLength: (name: string) => `${capitalize(name)} is too long`,
    minLength: (name: string, min: number) =>
      `${capitalize(name)} must contain at least ${min} character(s)`,
  },
  backToList: "Back to list",
  userNotFound: "User not found",
  userManagement: "User management",
  inviteModal: {
    closeBtn: "Close",
    content: (email: string) => `Email with sign up instructions was sent to ${email}.`,
    title: "Invite link sent",
    emailPlaceholder: "Enter email address",
    emailLabel: "Email",
    inviteTitle: "Invite user to Cyberhaven",
    inviteSubmitBtn: "invite",
    inviteBtn: "invite a member",
  },
  inviteEmail: {
    subject: "Sign Up to Cyberhaven",
    body: (link: string) => `Please follow this link ${link} to sign up`,
  },
  twoFactorAuth: {
    sectionTitle: "Two factor authentication",
    description: "Google Authenticator app",
    phoneDescription: "Setup phone MFA",
    phoneDescriptionMFAEnabled: "Reset phone MFA",
    descriptionSecondary: "",
    modalTitle: "Setup Google Authenticator app",
    actionDescription: "Scan or type the authenticator code into Google Authenticator app.",
    codeCopied: "Code copied",
    configure: "Configure",
    verifyCode: "Enter your 2FA code from Google Authenticator",
    verifyCodePlaceholder: "Enter your 2FA code from Google Authenticator",
    disable: "disable",
    disableTwoAuthModalTitle: "Are you sure you want to disable 2FA?",
    disableTwoAuthConfirm: "yes",
    copy: "Copy",
    download: "Download",
    notEnabled: "Not enabled",
  },
  recoveryCodes: {
    modalTitle: "Recovery codes",
    generateCodesBtn: "Generate backup codes",
    howToUse: "How to use it?",
    howToUseMessage:
      "Recovery codes are used to access your account in case you cannot receive two-factor authentication code. Keep these backup codes somewhere safe but accessible.",
    infoMessage: "Download or copy and save your recovery codes",
    copied: "Recovery codes was copied to the clipboard",
    fileTitle: "recovery_codes",
  },

  deleteAccText: "Delete account",
  deleteAccExp: "This action will permanently delete your account and all associated data.",
  deleteAccModalTitle: "Do you really want to delete this account?",
  deleteAccModalContent: "All user data will be permanently removed. This action is irreversible.",
  deleteAccBtn: "Delete account",
  accRoles: "Account roles",
  selectAccRoles: "Select account roles",
  edit: "Edit",
  accountManagement: "Account management",
  ok: "Ok",
  cancel: "Cancel",
  basicInfo: "Basic information",
  userName: "Name",
  changeName: "Change name",
  namePlaceholder: "Enter new name",
  companyEmail: "Company email",
  companyEmailPlaceholder: "Enter new email",
  changeEmail: "Change email",
  password: "Password",
  confirmYourPassword: "Your password",
  changePassword: "Change password",
  passwordTitle: "Type new password",
  repPassword: "Repeat password",
  loadingErrorMessage: "Something went wrong",

  userActions: {
    resetPassword: "Reset password",
    resetMFA: "Reset MFA",
    resetAuthProvider: "Reset auth provider",
    changeRole: "Change role",
    changeScope: "Change scope",
    deactivate: "Deactivate",
    delete: "Delete this user",
    confirm: "You are about to delete the user. Confirm?",
    confirmReset: {
      title: "Password reset confirmation",
      body: (isCurrentUser: boolean) =>
        `Further instructions on how to reset the password will be sent to ${
          isCurrentUser ? "your" : "user’s"
        } email. Are you sure you want to confirm this action and reset the password?`,
      confirm: "Yes, reset password",
      cancel: "Cancel",
    },
    confirmResetMFA: {
      title: "Mfa reset confirmation",
      body: (isCurrentUser: boolean) =>
        `After resetting MFA, ${
          isCurrentUser ? "you" : "user"
        } will be logged out immediately and asked to go through the MFA setup process again.  `,
      confirm: "Yes, reset MFA",
      cancel: "Cancel",
    },
    confirmResetAuthProvider: {
      title: "AUTHENTICATION PROVIDER RESET CONFIRMATION",
      body: (isCurrentUser: boolean) =>
        `Further instructions on how to reset authentication provider will be sent to ${
          isCurrentUser ? "your" : "user's"
        }  email. Are you sure you want to confirm this action and reset Provider?`,
      confirm: "Yes, reset Auth Provider",
      cancel: "Cancel",
    },
  },
  changeRole: {
    title: "Roles settings",
    warn: "At least 1 role should be selected",
    required: (pageName: string) => `This permission required in order to access ${pageName} page`,
    cancel: "Cancel",
    save: "Save",
    failedToUpdateRoles: "Failed to update roles.",
  },
  scopeDialog: {
    title: (isEditing: boolean) => `${isEditing ? "Edit" : "New"} Access Scope`,
    cancel: "Cancel",
    save: "Save",
    nameLabel: "Scope name",
    descriptionLabel: "Scope description (Optional)",
    definition: (entity: string) => `${capitalize(entity)} scope`,
    selectEntity: (entity: string) => `Select ${entity}`,
    include: "Include",
    exclude: "Exclude",
    fullAccess: "Full Access",
    includeRule: (entity: string) =>
      `User will only have access to events belonging to these ${entity}`,
    excludeRule: (entity: string) => `User will have access to all ${entity}, except the following`,
    fullAccessRule: (entity: string) => `User will have access to all ${entity}`,
    noData: (entity: string) => `There are no ${entity} yet.`,
  },
  scopeActions: {
    edit: "Edit",
    delete: "Delete",
    deleteConfirmTitle: "Delete scope",
    deleteConfirm: (scope: string) =>
      `Are you sure you want to permanently delete ${scope} scope? This action cannot be undone.`,
    canNotDeleteTitle: "Cannot delete",
    canNotDelete: `This scope is currently in use. To delete it, please remove this scope from all user permissions.`,
  },
  changeScope: {
    editTitle: (alias: string) => `Scope for ${alias}`,
    inviteTitle: "Select scope",
    failedToAssignScope: "Failed to assign scope.",
    cancel: "Cancel",
    save: "Save",
  },
  scopes: {
    failedToCreate: "Failed to create scope",
    failedToUpdate: "Failed to update scope",
    failedToDelete: "Failed to delete scope",
    cellLoading: "Loading...",
  },
  roles: {
    create: "Create a Role",
    cancel: "Cancel",
    save: "Save",
    roleName: "Role name",
    roleNameEnter: "Enter role name",
    roleDescription: "Role description",
    roleDescriptionEnter: "Enter role description",
    newRoleTitle: "New Role",
    editRoleTitle: "Edit Role",
    action: {
      edit: "Edit Role",
      delete: "Delete Role",
      confirm: "You are about to delete the role. Confirm?",
    },
  },
  usersTabs: {
    list: "Users",
    roles: "Roles",
  },
  userStatus: {
    active: "Active",
    inactive: "Inactive",
    pending: "Invite pending",
  },
};
